<template>
  <div>
    <div v-if="isFormError" class="error-container">
      {{ $t('guidance.forms.document.record.errorMessage') }}
    </div>
    <div>
      <p class="item-title">{{ $t('guidance.forms.document.record.supplier') }}</p>
      <el-select
        v-model="selectedSupplierIds"
        clearable
        multiple
        filterable
        :multiple-limit="10"
        :loading="isSuppliersLoading"
        :placeholder="$t('guidance.forms.document.record.placeholder.supplier')"
        class="w-100 item-input-rule-drawer"
      >
        <el-option
          v-for="supplier in suppliers"
          :key="supplier.id"
          :label="supplier.name"
          :value="supplier.id"
        ></el-option>
      </el-select>
    </div>

    <div class="field-container">
      <p class="item-title">{{ $t('guidance.forms.document.record.business') }}</p>
      <el-select
        v-model="selectedBusinessIds"
        clearable
        multiple
        filterable
        :multiple-limit="10"
        :loading="isTenantsLoading"
        :placeholder="$t('guidance.forms.document.record.placeholder.business')"
        class="w-100 item-input-rule-drawer"
      >
        <el-option v-for="tenant in tenants" :key="tenant.id" :label="tenant.name" :value="tenant.id"></el-option>
      </el-select>
    </div>

    <div class="field-container">
      <p class="item-title">{{ $t('guidance.forms.document.record.documentType') }}</p>
      <el-select
        v-model="selectedDocumentTypes"
        clearable
        multiple
        filterable
        :placeholder="$t('guidance.forms.document.record.placeholder.documentType')"
        class="w-100 item-input-rule-drawer"
      >
        <el-option
          v-for="documentType in documentTypes"
          :key="documentType"
          :label="$t(`document.exports.schema.type.fullName.${documentType}`)"
          :value="documentType"
        >
        </el-option>
      </el-select>
    </div>

    <div class="field-container">
      <p class="item-title">{{ $t('guidance.forms.document.record.template') }}</p>
      <el-select
        v-model="selectedTemplateIds"
        clearable
        multiple
        filterable
        :loading="isLoadingTemplate"
        :placeholder="$t('guidance.forms.document.record.placeholder.template')"
        :no-data-text="$t('guidance.forms.document.record.placeholder.noTemplates')"
        :disabled="!doesTemplateFieldAvailable"
        class="w-100 item-input-rule-drawer"
      >
        <el-option
          v-for="template in templates"
          :key="template.id"
          :label="template.name"
          :value="template.id"
        ></el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { useSuppliersNew } from '@/modules/business/compositions';
import { useAllTenants } from '@/modules/core/compositions/all-tenants';
import { useTemplates } from '@/modules/automation';
import { DOCUMENT_TYPES } from '@/modules/document/types';
import { isEmpty } from 'ramda';

export const DOCUMENT_KEYS = {
  SUPPLIER_ID: 'supplierId',
  BUSINESS_ID: 'businessId',
  DOCUMENT_TYPE: 'documentType',
  TEMPLATE_ID: 'templateId',
};

export default {
  setup() {
    const selectedSupplierIds = ref([]);
    const selectedBusinessIds = ref([]);
    const selectedDocumentTypes = ref([]);
    const selectedTemplateIds = ref([]);

    watch([selectedSupplierIds, selectedBusinessIds, selectedDocumentTypes], () => {
      isFormError.value = false;
    });

    const { suppliers, loading: isSuppliersLoading } = useSuppliersNew();
    const { tenants, loading: isTenantsLoading } = useAllTenants();
    const documentTypes = DOCUMENT_TYPES;

    const doesTemplateFieldAvailable = computed(() => {
      return selectedSupplierIds.value.length === 1 && selectedDocumentTypes.value.length === 1;
    });

    const { templates, loading: isLoadingTemplate } = useTemplates(
      computed(() => {
        const [supplierId] = selectedSupplierIds.value;
        const [documentType] = selectedDocumentTypes.value;

        return {
          supplierId: supplierId,
          documentType: documentType,
          signedUrl: false,
          isArchived: false,
        };
      }),
      computed(() => ({ enabled: doesTemplateFieldAvailable }))
    );

    watch(doesTemplateFieldAvailable, () => {
      // Clear selected template ids when turn to not available
      if (!doesTemplateFieldAvailable.value && selectedTemplateIds.value.length) selectedTemplateIds.value = [];
    });

    const isFormError = ref(false);

    const validateFilters = () => {
      // At lest one of the fields must have one value
      return (
        !isEmpty(selectedSupplierIds.value) ||
        !isEmpty(selectedBusinessIds.value) ||
        !isEmpty(selectedDocumentTypes.value)
      );
    };

    const onSubmit = () => {
      const isValid = validateFilters();

      if (!isValid) {
        isFormError.value = true;
        throw new Error('Document record Guidance Form invalid');
      }

      const filters = [];

      if (!isEmpty(selectedSupplierIds.value))
        filters.push({ key: DOCUMENT_KEYS.SUPPLIER_ID, values: selectedSupplierIds.value });

      if (!isEmpty(selectedBusinessIds.value))
        filters.push({ key: DOCUMENT_KEYS.BUSINESS_ID, values: selectedBusinessIds.value });

      if (!isEmpty(selectedDocumentTypes.value))
        filters.push({ key: DOCUMENT_KEYS.DOCUMENT_TYPE, values: selectedDocumentTypes.value });

      if (!isEmpty(selectedTemplateIds.value))
        filters.push({ key: DOCUMENT_KEYS.TEMPLATE_ID, values: selectedTemplateIds.value });

      return filters;
    };

    return {
      onSubmit,
      selectedSupplierIds,
      isSuppliersLoading,
      isTenantsLoading,
      isLoadingTemplate,
      suppliers: computed(() => [...suppliers.value].sort((a, b) => a.name.localeCompare(b.name))),
      tenants,
      selectedBusinessIds,
      selectedDocumentTypes,
      documentTypes,
      doesTemplateFieldAvailable,
      selectedTemplateIds,
      templates,
      isFormError,
    };
  },
};
</script>

<style lang="scss" scoped>
.field-container {
  margin-top: 10px;
}

.item-title {
  font-size: small;
  font-weight: 500;
}

.error-container {
  color: red;
}
</style>

<style>
.item-input-rule-drawer {
  .el-select__tags {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}
</style>
