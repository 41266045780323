import DocumentRecordGuidanceForm, {
  DOCUMENT_KEYS as RECORD_DOCUMENT_KEYS,
} from './forms/DocumentRecordGuidanceForm.vue';

/**
 * If you'd like to add guidances support for more task types, follow these steps:
 *
 * Support adding new guidances
 * ----------------------------
 * 1. Create a component providing the guidance filters for the "add new guidance drawer". See DocumentRecordGuidanceForm
 * 2. Use the `onSubmit` function to return the filters (it will be called when the guidance "save" button is clicked)
 * 3. Add a new key to the object exported from this file, in the form of <taskType>_<domain>, and the `form` key to your new component
 *
 * Support matching form data with guidances
 * -----------------------------------------
 * 1. Go to the main component that holds SingularTaskLayout
 *    Make sure to call SingularTaskLayout's onContentChanged function whenever the form data changes
 *    See RecordDocuments.notifyTaskLayoutOnFormDataChange for an example
 * 2. Implement the extractFormData function below (it will be called when trying to match guidance filters to form data)
 */

export default {
  record_document: {
    extractFormData(formData, field) {
      if (field === RECORD_DOCUMENT_KEYS.BUSINESS_ID) {
        return formData.businessId;
      } else if (field === RECORD_DOCUMENT_KEYS.SUPPLIER_ID) {
        return formData.supplierId;
      } else if (field === RECORD_DOCUMENT_KEYS.TEMPLATE_ID) {
        return formData.templateId;
      } else if (field === RECORD_DOCUMENT_KEYS.DOCUMENT_TYPE) {
        return formData.type;
      }
    },
    form: DocumentRecordGuidanceForm,
  },
};
