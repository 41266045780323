<template>
  <el-drawer
    :visible="visible"
    :direction="drawerDirection"
    :modal="false"
    :show-close="false"
    :size="drawerWidthPx"
    @close="$emit('close-guidance-drawer')"
  >
    <template #title>
      <div class="drawer-title-container">
        <h2 class="text-typography-primary">
          {{ $t('guidance.drawer.title') }}
        </h2>
        <h3 class="text-typography-primary drawer-sub-title-container">
          {{ $t('guidance.drawer.subTitle') }}
        </h3>
      </div>
    </template>

    <div class="h-100 drawer-container">
      <div class="drawer-form-container">
        <div>
          <p class="item-title">{{ $t('guidance.drawer.form.fields.taskType') }}</p>
          <el-select v-model="selectedTaskType" clearable class="w-100 item-input item-input-rule-drawer">
            <el-option
              v-for="taskType in taskTypes"
              :key="taskType"
              :label="$t(`guidance.drawer.taskTypes.${taskType}`)"
              :value="taskType"
            ></el-option>
          </el-select>
        </div>

        <component :is="selectedForm" v-if="selectedForm" ref="taskTypeForm" />

        <div v-if="selectedForm" class="drawer-guidance-title-container">
          <div class="drawer-guidance-title">
            <h3 class="text-typography-primary">
              {{ $t('guidance.drawer.guidanceTitle') }}
            </h3>
          </div>
          <div class="drawer-guidance-field">
            <p class="item-title">{{ $t('guidance.drawer.form.fields.guidance') }}</p>
            <el-input v-model="guidanceText" type="textarea" class="pb-1" />
          </div>
        </div>
      </div>
      <div class="drawer-bottom-container">
        <div class="line-break w-100"></div>
        <div :class="['drawer-submit-container', $direction === 'rtl' ? 'ltr-buttons' : 'rtl-buttons']">
          <Button class="drawer-btn" type="secondary" @click="() => $emit('close-guidance-drawer')">
            {{ $t('guidance.drawer.form.controllers.cancel') }}
          </Button>

          <Button class="drawer-btn" type="primary" :disabled="isCreateGuidanceDisabled" @click="handleSubmit">
            {{ $t('guidance.drawer.form.controllers.submit') }}
          </Button>
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { ref, computed, getCurrentInstance } from 'vue';
import { createGuidance } from './composition';
import guidanceTypes from './guidanceTypes';
import { Button } from '@/modules/core';
import { isNil, isEmpty } from 'ramda';

const GUIDANCE_MIN_LENGTH = 3;

export default {
  components: { Button },
  props: {
    visible: { type: Boolean, default: false },
  },
  setup(_props, { emit }) {
    const drawerWidthPx = 428;
    const instance = getCurrentInstance();
    const i18 = instance?.proxy.$i18n;
    const $direction = instance?.proxy.$direction;

    // Opposite the regular direction.
    const drawerDirection = computed(() => {
      return $direction === 'ltr' ? 'rtl' : 'ltr';
    });

    const taskTypes = computed(() => Object.keys(guidanceTypes));
    const selectedTaskType = ref('');
    const guidanceText = ref('');

    const isCreateGuidanceDisabled = computed(
      () => guidanceText.value.length < GUIDANCE_MIN_LENGTH || isEmpty(selectedTaskType.value)
    );

    const selectedForm = computed(() => {
      if (selectedTaskType.value) return guidanceTypes[selectedTaskType.value].form;
      return null;
    });

    const taskTypeForm = ref(null); // Ref to the dynamic form component

    const taskTypeSubmitForm = () => {
      if (taskTypeForm.value && taskTypeForm.value.onSubmit) {
        const filters = taskTypeForm.value.onSubmit();
        return filters;
      }
    };

    const handleSubmit = async () => {
      try {
        const guidanceFormFilters = taskTypeSubmitForm();
        if (isNil(guidanceFormFilters))
          throw new Error(`injected form of ${selectedTaskType.value} returned empty filters`);

        const guidance = {
          taskType: selectedTaskType.value,
          filters: [...guidanceFormFilters],
          guidance: guidanceText.value,
        };

        await createGuidance(guidance);
        emit('guidance-created');
        formSubmitSuccess();
      } catch (error) {
        // its the responsibility of the injected form to show the form error on their fields
        console.error(`injected form of ${selectedTaskType.value} returned an error ${error}`);
      }
    };

    const formSubmitSuccess = () => {
      clearDrawerForm();
      emit('close-guidance-drawer');
      instance.proxy.$message.success(i18.t('commons.messages.action.success'));
      emit('on-success');
    };

    const clearDrawerForm = () => {
      guidanceText.value = '';
      selectedTaskType.value = '';
    };

    return {
      drawerWidthPx,
      drawerDirection,
      taskTypes,
      selectedTaskType,
      handleSubmit,
      selectedForm,
      guidanceText,
      taskTypeForm,
      isCreateGuidanceDisabled,
    };
  },
};
</script>

<style lang="scss" scoped>
.drawer-container {
  display: flex;
  flex-direction: column;
}

.drawer-form-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.drawer-bottom-container {
  margin-top: auto;
}

.line-break {
  height: 1px;
  background-color: #e3e4e6;
}

.drawer-submit-container {
  margin-top: auto;
  display: flex;
  padding-bottom: 20px;
  padding-top: 20px;
}

.ltr-buttons {
  justify-content: flex-end;
  padding-left: 20px;
}

.rtl-buttons {
  justify-content: flex-start;
  padding-right: 20px;
}

.drawer-btn {
  height: 40px;
  padding-left: 16px;
  padding-left: 16px;
}

.drawer-title-container {
  padding-top: 20px;
}
.drawer-sub-title-container {
  padding-top: 40px;
}
.drawer-guidance-title-container {
  padding-top: 20px;
}
.drawer-guidance-title {
  padding-bottom: 20px;
}
.drawer-guidance-field {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.item-title {
  font-size: small;
  font-weight: 500;
}

.item-input {
  margin-top: 5px;
  .el-select__tags {
    padding: 10px;
  }
}
</style>
<style>
.item-input-rule-drawer {
  margin-top: 5px;
  .el-select__tags {
    padding-right: 5px;
    padding-left: 5px;
  }
}
</style>
