import { gql } from '@apollo/client/core';
import { apolloClient } from '@/plugins/apollo-client';

export const createGuidance = (variables) => apolloClient.mutate({ mutation: MUTATION_CREATE_GUIDANCE, variables });

const MUTATION_CREATE_GUIDANCE = gql`
  mutation GuidanceCreate($taskType: String!, $filters: JSON!, $guidance: String!) {
    guidanceCreate(taskType: $taskType, filters: $filters, guidance: $guidance) {
      id
      taskType
      guidance
      filters
      createdAt
      createdBy {
        firstName
      }
    }
  }
`;
